import React from 'react';
//import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../images/n-logo2.png';
import linkedin from '../images/Icons/linkedin.png';
import facebook from '../images/Icons/facebook.png';
import instagram from '../images/Icons/instagram.png';
import twitter from '../images/Icons/twitter.png';
import mastadom from '../images/Icons/mastodon.png';
import tumblr from '../images/Icons/tumblr.png';



const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full   ">

                <div className="bg-gray-100 py-10 px-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        {/* Left Section: Logo */}
        <div className="flex flex-col items-center md:items-start">
          {/* Logo */}
          <div className="flex items-center mb-6">
            {/* <span className="text-black text-7xl font-serif font-bold">n</span>
            <span className="text-blue-600 text-7xl font-bold">.</span> */}
            <img alt="card img" className="rounded-t float-right duration-1000 w-28 md:w-48" src={logo} />
          </div>

          {/* Social Icons */}
          <div className="flex space-x-4">
            {/* Replace # with the URLs to your social pages */}
            <a
              href="https://www.linkedin.com/company/negoify"
              className="w-8 h-8 flex items-center justify-center bg-black text-white rounded-md"
            >
              {/* <i className="fab fa-linkedin-in"></i> */}
              <img alt="card img"  src={linkedin} />
            </a>
            <a
              href="https://web.facebook.com/negoify"
              className="w-8 h-8 flex items-center justify-center bg-black text-white rounded-md"
            >
              {/* <i className="fab fa-facebook-f"></i> */}
              <img alt="card img"  src={facebook} />

            </a>
            <a
              href="https://www.instagram.com/negoify"
              className="w-8 h-8 flex items-center justify-center bg-black text-white rounded-md"
            >
              {/* <i className="fab fa-instagram"></i> */}
              <img alt="card img"  src={instagram} />

            </a>
            <a
              href="https://www.twitter.com/negoify"
              className="w-8 h-8 flex items-center justify-center bg-black text-white rounded-md"
            >
              {/* <i className="fab fa-x-twitter"></i> */}
              <img alt="card img"  src={twitter} />

            </a>
            <a
              href="https://mastodon.social/@negoify"
              className="w-8 h-8 flex items-center justify-center bg-black text-white rounded-md"
            >
              {/* <i className="fab fa-mastadom"></i> */}
              <img alt="card img"  src={mastadom} />

            </a>
            <a
              href="https://www.tumblr.com/negoify"
              className="w-8 h-8 flex items-center justify-center bg-black text-white rounded-md"
            >
              {/* <i className="fab fa-mastadom"></i> */}
              <img alt="card img"  src={tumblr} />

            </a>
          </div>
        </div>

        {/* Right Section: Contact Info */}
        <div className="text-center md:text-left mt-8 md:mt-0">
          {/* Address */}
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-6 h-6 bg-black text-white rounded-md flex items-center justify-center">
              <i className="fas fa-map-marker-alt w-6 sm:w-3"></i>
            </span>
            <p className="text-gray-700">
              negoify Pvt. Ltd. 21 km, Ferozpur Road Near Hakim Chowk, 54600
              Lahore Pakistan
            </p>
          </div>
          <hr className="border-t border-blue-600 mb-4" />

          {/* Office Hours */}
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-6 h-6 bg-black text-white rounded-md flex items-center justify-center">
              <i className="fas fa-clock w-6 sm:w-4"></i>
            </span>
            <p className="text-gray-700">
              <strong>Office hours:</strong> Monday - Friday: 09:00 AM - 06:00 PM
            </p>
          </div>
          <hr className="border-t border-blue-600 mb-4" />

          {/* Email */}
          <div className="flex items-center space-x-4">
            <span className="w-6 h-6 bg-black text-white rounded-md flex items-center justify-center">
              <i className="fas fa-envelope w-6 sm:w-4"></i>
            </span>
            <p className="text-gray-700">office(at)negoify(dot)com</p>
          </div>
        </div>
      </div>
                </div>
                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4 bg-grey">
                <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                    <div className="text-sm text-200 font-semibold py-1">
                    Copyright &copy; {new Date().getFullYear()}{"  "}
                    <HashLink
                        to="#"
                        className=" hover:text-gray-900"
                    >
                        negoify Pvt Ltd
                    </HashLink>. All rights reserved.
                    </div>
                </div>
                    </div>

                </div>
               
            </footer>
        </>
    )
}
export default Footer;
