import React, { useEffect } from 'react';
import img from '../images/intro.png';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const Intro = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Run animations only once
    });
  }, []);

  return (
    <>
      <div className="bg-white py-16 px-4 sm:px-6 lg:px-8" id="about">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-10">
          {/* Left Section: Illustration */}
          <div
            className="flex justify-center order-2 lg:order-1"
            data-aos="fade-right" // Fade-in from the right
          >
            <img
              src={img}
              alt="Illustration"
              className="w-full max-w-md  object-contain transform hover:scale-105 transition-transform duration-500"
            />
          </div>

          {/* Right Section: About Us Content */}
          {/* Right Section: About Us Content */}
          <div data-aos="fade-left" data-aos-delay="200" className="order-1 lg:order-2">
            <h2 className="text-3xl font-bold text-[#186eb4] mb-6">about</h2>
            <div className="space-y-8">
              {/* First Item */}
              <div className="flex items-start space-x-4">
                <div className="text-[#186eb4] text-3xl">
                  <i className="fas fa-file-alt"></i>
                </div>
                <p className="text-gray-700 text-lg">
                  Our clients should experience the best possible service. You don't want to
                  spend your precious budget before you see first results? We will work
                  something out and provide our services the first few hours free of charge.
                </p>
              </div>
              {/* Second Item */}
              <div className="flex items-start space-x-4">
                <div className="text-[#186eb4] text-3xl">
                  <i className="fas fa-cube"></i>
                </div>
                <p className="text-gray-700 text-lg">
                  We are a legally registered company at the Federal Board of Revenue,
                  Pakistan. We are legit, official, and transparent. Feel free to visit us in
                  our office in Lahore and have some tea with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
