import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-bold  hover:text-blue-900" smooth to="/#about">
                about
            </HashLink>
            <HashLink className="px-4 font-bold  hover:text-blue-900" smooth to="/#solutions">
                solutions
            </HashLink>
            <HashLink className="px-4 font-bold  hover:text-blue-900" smooth to="/#pricing">
                offers
            </HashLink>
            <HashLink className="px-4 font-bold  hover:text-blue-900" smooth to="/#Clients">
                clients
            </HashLink>
            <HashLink className="px-4 font-bold  hover:text-blue-900" smooth to="/#Team">
                team
            </HashLink>
            <HashLink className="px-4 font-bold  hover:text-blue-900" to="/contact#contact">
                contact
            </HashLink>
            
        </>
    )
}

export default NavLinks;
